import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import configGenerator from "@resourcehub/resourcehub-configuration";
import {
    ButtonStyled,
    LeadGenerationThankYou,
    PageTemplateWrapper,
    TitleWrapper
} from "@resourcehub/resourcehub-components";
import { leadGenerationStore } from "@resourcehub/resourcehub-data";

const { leadGenerationActions, leadGenerationSelectors } = leadGenerationStore;

const LayoutDiv = styled.div`
    label: LayoutDiv;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    > div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 1025px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

class PricingRequestCompletePage extends React.Component {
    constructor(props) {
        super(props);
        const { data } = props;
        const { site } = data;
        const config = configGenerator({
            market: site.siteMetadata.market,
            site: "education-primary",
            locale: site.siteMetadata.lang,
            page: "pricing-request-complete"
        });
        this.state = {
            config
        };
    }
    render() {
        const { config } = this.state;
        return (
            <PageTemplateWrapper config={config}>
                <LayoutDiv>
                    <LeadGenerationThankYou />
                    <div>
                        <TitleWrapper {...config.page.content.title} />
                        <TitleWrapper {...config.page.content.sub_title} />
                        <ButtonStyled {...config.page.content.resources_link} />
                    </div>
                </LayoutDiv>
            </PageTemplateWrapper>
        );
    }
}

PricingRequestCompletePage.propTypes = { data: PropTypes.any };

// Selectors
const mapStateToProps = (state) => ({
    submissionStatus: leadGenerationSelectors.getSubmissionStatus(state)
});

export default connect(mapStateToProps, leadGenerationActions)(PricingRequestCompletePage);

export const pageQuery = graphql`
    query {
        site {
            ...SiteFragment
        }
    }
`;
